<template>
  <el-menu
    :default-active="activeMenu"
    :collapse="sidebar.opened"
    background-color="#545c64"
    text-color="#fff"
    active-text-color="#ffd04b"
	:collapse-transition="false"
  >
    <ul
      class="menu-wrapper"
      :class="{collapse: sidebar.opened}"
      v-for="menu in menuData"
      :key="menu.path"
    >
      <MenuItem :menu="menu" :isCollapse.sync="sidebar.opened" />
    </ul>
  </el-menu>
</template>
<style lang="scss" scoped>
/deep/ .collapse {
  .el-submenu__icon-arrow {
    display: none !important;
  }
}
</style>
<script>
import { mapGetters } from 'vuex'
import { routes } from '@/router'
import MenuItem from './MenuItem'
export default {
  name: 'SideMenus',
  components: {
    MenuItem
  },
  data() {
    return {
      isCollapse: false,
      menuData: routes
    }
  },
  computed: {
	...mapGetters(['sidebar', 'functionInfoList']),
    activeMenu() {
      const route = this.$route
      const { meta, path } = route
      // if set path, the sidebar will highlight the path you set
      if (meta.activeMenu) {
        return meta.activeMenu
      }
      return path
    }
  },
  mounted () {
    this.setMenu (this.functionInfoList)
  },
  methods: {
    setMenu (inputMenu = []) {
      let meaulist = []
      let meaulistinit = []
      let menu = []
      inputMenu.forEach((ele) => {
        if (ele.featureStatus !== 3) {
          if (ele.privilegeMark.includes('TMC/Menu')) {
            meaulist.push(ele.privilegeMark.replace('TMC/Menu', ''))
          }
        }
      })
      routes.forEach((i) => {
        var prearr = []
        i.children.forEach((q) => {
          if (meaulist.includes(q.path) || q.meta.dev) {
            prearr.push({ ...q })
            menu = i
          }
        })
        if (prearr && prearr.length !== 0) {
          menu.children = prearr
          meaulistinit.push(menu)
        }
      })
      this.menuData = [routes[0], ...meaulistinit]
    }
  }
}
</script>