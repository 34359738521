<template>
  <div class="menu-item-wrap">
    <template
      v-if="(menu.children  && menu.children[0].meta.hidden) || isNest&&!menu.children || !menu.children && !isNest"
    >
      <AppLink :to="resolvePath(menu.path)">
        <el-menu-item :index="resolvePath(menu.path)" v-if="!isCollapse || isNest">
          <i :class="[menu.meta.icon]"></i>
          <span>{{menu.meta.title}}</span>
        </el-menu-item>
        <el-tooltip v-else class="item" effect="dark" :content="menu.meta.title" placement="left">
          <el-menu-item :index="resolvePath(menu.path)">
            <i :class="[menu.meta.icon]"></i>
          </el-menu-item>
        </el-tooltip>
      </AppLink>
    </template>

    <el-submenu :index="resolvePath(menu.path)" popper-append-to-body v-else>
      <template slot="title">
        <i :class="[menu.meta.icon]"></i>
        <span v-if="!isCollapse">{{menu.meta.title}}</span>
      </template>
      <template v-if="menu.children">
        <SubMenuItem
          v-for="child in menu.children"
          :key="child.path"
          :menu="child"
          :is-nest="true"
          :base-path="resolvePath(menu.path)"
          :isCollapse="isCollapse"
        />
      </template>
    </el-submenu>
  </div>
</template>

<style lang="scss">
 a {
	 text-decoration: none;
 }
</style>

<script>
import { isExternal } from '@/utils/validate'
import path from 'path'
import AppLink from './Link'
export default {
  name: 'SubMenuItem',
  components: {
    AppLink
  },
  props: {
    menu: {
      type: [Array, Object],
      default() {
        return []
      }
    },
    isCollapse: {
      type: Boolean,
      default() {
        return false
      }
    },
    isNest: {
      type: Boolean,
      default() {
        return false
      }
    },
    basePath: {
      type: String,
      default: ''
    }
  },
  methods: {
    resolvePath(routePath) {
      if (isExternal(routePath)) {
        return routePath
      }
      if (isExternal(this.basePath)) {
        return this.basePath
	    }
      return path.resolve(this.basePath, routePath)
    }
  }
}
</script>