<style lang="scss" scoped>
.layout-wrap{
	height: 100vh;
	width: 100%;
	overflow-y: auto;
	.side{
		background: #303133;
	}
	.main{
		min-width: 700px;
	}
	.header{
		background: #DCDFE6;
		width: 100%;
		.left-box,.right-box{
			display: flex;
			align-items: center;
			font-size: 26px;
			/deep/ i{
				cursor: pointer;
			}
		}
	}
	.content{
		background: #f0f0f0;
		box-sizing: border-box;
		height: calc(100vh - 60px);
		overflow: auto;
		width: 100%;
	}
}
.message-box{
	margin-top: -5px;
	position: relative;
	display: inline-block;
	margin-right: 5px;
	.count{
		position: absolute;
		font-size: 12px;
		background:#f7653e;
		min-width: 16px;
		color: #fff;
		display: inline-block;
		padding: 0px 4px;
		border-radius: 10px;
		text-align: center;
		left: 7px;
		top: 3px;
	}
	i{
		color: #606266;
		font-size: 18px;
	}
}
</style>

<template>
  <el-container class="layout-wrap">
    <el-aside :width="sidebar.opened?'63px':'200px'" class="side">
		<Menu ref="menu"/>
	</el-aside>
    <el-container class="main">
      <el-header  class="header">
        <el-row type="flex" justify="space-between" align="center" style="height:60px">
			<div class="left-box">
				<i :class="{'el-icon-s-fold': !sidebar.opened,'el-icon-s-unfold': sidebar.opened}" @click="toggleFole"></i>
				&nbsp;  <Breadcrumb style="margin-left:20px" />
			</div>
			<div class="right-box">
				<span class="message-box" @click="showMessageBox = !showMessageBox">
					<span class="count">{{count > 0 ? count > 100 ? '99+': count: ''}}</span>
					<i class="el-icon-message-solid"></i>
				</span>
				<LangSelect style="line-height:60px;width:40px;text-align:center"/>
				<User/>
			</div>
		</el-row>
      </el-header>
	  <el-main class="content">
		<transition name="fade-transform" mode="out-in">
		<keep-alive :include="cache">
			<router-view :key="key" />
		</keep-alive>
    </transition>
	  </el-main>
    </el-container>
		<el-dialog title="" :visible.sync="showMessageBox" @closed="getCount" width="900px">
			<MessageBox/>
		</el-dialog>
  </el-container>
</template>

<script>
import LangSelect from '@/components/LangSelect'
import Menu from './components/Menu'
import User from './components/User'
import Breadcrumb from './components/Breadcrumb'
import MessageBox from './components/MessageBox.vue'
import { countNotification } from '@/service'
import { mapGetters } from 'vuex'
let crrentCache = []
let timer = null
export default {
	name: 'Layout',
	components: {
		LangSelect,
		Breadcrumb,
		Menu,
		MessageBox,
		User
	},
	data() {
		return {
			showMessageBox: false,
			count: 0
		}
	},
	computed: {
		...mapGetters(['sidebar']),
		key() {
			return this.$route.path
		},
		cache() {
			crrentCache = this.$route.meta.cache && this.$route.name  ? [this.$route.name] : this.$route.meta.inline ? crrentCache : []
			return crrentCache
		}
	},
	mounted() {
		this.getCount()
		timer = setInterval(() => {
			this.getCount()
		}, 30000);
	},
	beforeDestroy() {
		clearInterval(timer)
	},
	methods: {
		getCount() {
			countNotification({valid: 0}).then(res => {
				if(res.code === 0) {
					this.count = res.data
				}
			})
		},
		toggleFole() {
			this.$store.dispatch('app/toggleSideBar')
		}
	}
}
</script>