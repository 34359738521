import router from '@/router'
import store from '@/store'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style



NProgress.configure({ showSpinner: false }) // NProgress Configuration
const whiteList = ['/login','/404']
router.beforeEach(async(to, from, next) => {
  // start progress bar
  NProgress.start()
  if(store.getters.token) {
    next()
  } else if(whiteList.indexOf(to.path) !== -1){
    next()
  } else {
    next(`/login`)
    NProgress.done()
  }
  
})

router.afterEach(() => {
  // finish progress bar
  NProgress.done()
})
