import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/components/Layout'
Vue.use(VueRouter)

const defaultRoute = [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/index.vue')
  },
  {
    path: '/404',
    name: '404',
    component: () => import('@/views/errorPage/404')
  },
  {
    path: '/401',
    name: '401',
    component: () => import('@/views/errorPage/401')
  },
  {
    path: '*',
    component: () => import('@/views/errorPage/404')
  }
]

export const routes = [
         {
           path: '/',
           name: '',
           component: Layout,
           meta: {
             title: 'Dashboard',
             icon: 'el-icon-s-home'
           },
           children: [
             {
               path: '/',
               name: 'dashboard',
               component: () => import('@/views/dashboard/index.vue'),
               meta: {
                 hidden: true
               }
             }
           ]
         },

         // {
         //   path: '/topic-monitoring',
         //   name: 'Topic',
         //   component: Layout,
         //   meta: {
         //     title: 'Topic Monitoring',
         //     icon: 'el-icon-view'
         //   },
         //   children: [
         //     {
         //       path: '/topic-monitoring',
         //       component: () => import('@/views/topicMonitoring'),
         //       meta: {
         //         hidden: true
         //       }
         //     }
         //   ]
         // },
         {
           path: '/playerMarkets',
           name: 'home',
           redirect: '',
           component: Layout,
           meta: {
             title: 'Standard Library',
             icon: 'el-icon-s-data'
           },
           children: [
             {
               path: '/',
               name: 'Player Market',
               component: () => import('@/views/standard/playerMarket.vue'),
               meta: {
                 title: 'Player Market',
                 cache: false
               }
             }
           ]
         },
         {
           path: '/standard',
           name: 'home',
           redirect: '/match',
           component: Layout,
           meta: {
             title: 'Standard Library',
             icon: 'el-icon-s-data'
           },
           children: [
             // {
             //   path: '/sports',
             //   name: 'Sports',
             //   component: () => import('@/views/standard/sports.vue'),
             //   meta: {
             //     title: 'Sports',
             //     cache: false
             //   }
             // },
             {
               path: '/region',
               name: 'Region',
               component: () => import('@/views/standard/region.vue'),
               meta: {
                 title: 'Region',
                 cache: false
               }
             },
             {
               path: '/tournament',
               name: 'Tournament',
               component: () => import('@/views/standard/tournament.vue'),
               meta: {
                 title: 'Tournament'
               }
             },
             {
               path: '/team',
               name: 'Team',
               component: () => import('@/views/standard/team.vue'),
               meta: {
                 title: 'Team'
               }
             },
             // {
             //   path: 'market',
             //   component: () => import('@/views/standard/market.vue'),
             //   meta: {
             //     title: 'Market Type'
             //   }
             // },
             // {
             //   path: '/market-type',
             //   component: () => import('@/views/standard/marketType.vue'),
             //   meta: {
             //     title: 'Market Type'
             //   }
             // },
             // {
             //   path: '/event-code',
             //   component: () => import('@/views/standard/eventCode.vue'),
             //   meta: {
             //     title: 'Event Code'
             //   }
             // },
             // {
             //   path: '/event-type',
             //   component: () => import('@/views/standard/eventType.vue'),
             //   meta: {
             //     title: 'Event Type'
             //   }
             // },
             // {
             //   path: '/match-event',
             //   component: () => import('@/views/standard/matchEvent.vue'),
             //   meta: {
             //     title: 'Match Events'
             //   }
             // },
             {
               path: '/match',
               name: 'Match',
               component: () => import('@/views/standard/match.vue'),
               meta: {
                 title: 'Match',
                 cache: false
               }
             },
             {
               path: '/player',
               name: 'Player',
               component: () => import('@/views/standard/team.vue'),
               meta: {
                 title: 'Player'
               }
             },
             {
               path: '/outright-match',
               name: 'Match',
               component: () => import('@/views/standard/match.vue'),
               meta: {
                 title: 'Outright Match',
                 cache: true
               }
             },
             // {
             //   path: '/period',
             //   component: () => import('@/views/standard/period.vue'),
             //   meta: {
             //     title: 'Period'
             //   }
             // },
             // {
             //   path: '/option',
             //   component: () => import('@/views/standard/option.vue'),
             //   meta: {
             //     title: 'Option'
             //   }
             // },
             // {
             //   path: '/match-result',
             //   component: () => import('@/views/standard/resultMatch.vue'),
             //   meta: {
             //     title: 'Match Result'
             //   }
             // },
             // {
             //   path: '/match-period',
             //   component: () => import('@/views/standard/matchPeriod.vue'),
             //   meta: {
             //     title: 'Match Period'
             //   }
             // },
             // {
             //   path: '/match-status',
             //   component: () => import('@/views/standard/matchStatus.vue'),
             //   meta: {
             //     title: 'Match Status'
             //   }
             // },
             // {
             //   path: 'outright-tournament',
             //   component: () => import('@/views/standard/outrightTournament.vue'),
             //   meta: {
             //     title: 'Outright tournament'
             //   }
             // },
             {
               path: '/international',
               component: () =>
                 import('@/views/standard/international/index.vue'),
               meta: {
                 title: 'Internationalization'
               }
             }
             // {
             //   path: 'match-market-status',
             //   component: () => import('@/views/standard/matchMarketStatus.vue'),
             //   meta: {
             //     title: 'Match Market Status'
             //   }
             // }
           ]
         },
         {
           path: '/subscibe',
           name: 'subscibe',
           redirect: '/match-mapping',
           component: Layout,
           meta: {
             title: 'Subscribe',
             icon: 'el-icon-data-line'
           },
           children: [
             {
               path: '/match-mapping',
               name: 'MatchMapping',
               component: () => import('@/views/subscibe/matchMapping.vue'),
               meta: {
                 title: 'Match Mapping',
                 cache: false
               }
             },
             {
               path: '/player-mapping',
               component: () => import('@/views/subscibe/teamMapping.vue'),
               meta: {
                 title: 'Player Mapping'
               }
             },
             {
               path: '/outright-match-mapping',
               name: 'MatchMapping',
               component: () => import('@/views/subscibe/matchMapping.vue'),
               meta: {
                 title: 'Outright Match Mapping',
                 cache: true
               }
             },
             {
               path: '/market-mapping',
               component: () => import('@/views/subscibe/marketMapping.vue'),
               meta: {
                 title: 'Market Mapping',
                 inline: true
               }
             },
             // {
             //   path: 'outright-tournament',
             //   component: () =>
             //     import('@/views/subscibe/outrightTournamentMapping.vue'),
             //   meta: {
             //     title: 'Outright Mapping'
             //   }
             // },
             {
               path: '/event-code-mapping',
               component: () => import('@/views/subscibe/eventCodeMapping.vue'),
               meta: {
                 title: 'Event Code Mapping'
               }
             },
             // {
             //   path: 'event-type-mapping',
             //   component: () => import('@/views/subscibe/eventTypeMapping.vue'),
             //   meta: {
             //     title: 'Event Type Mapping'
             //   }
             // },
             {
               path: '/sports-mapping',
               component: () => import('@/views/subscibe/sportsMapping.vue'),
               meta: {
                 title: 'Sports Mapping'
               }
             },
             {
               path: '/region-mapping',
               component: () => import('@/views/subscibe/regionMapping.vue'),
               meta: {
                 title: 'Region Mapping'
               }
             },
             {
               path: '/team-mapping',
               component: () => import('@/views/subscibe/teamMapping.vue'),
               meta: {
                 title: 'Team Mapping'
               }
             },
             {
               path: '/tournament-mapping',
               component: () =>
                 import('@/views/subscibe/tournamentMapping.vue'),
               meta: {
                 title: 'Tournament Mapping'
               }
             }
           ]
         },
         {
           path: '/season-management',
           name: 'Season',
           component: Layout,
           meta: {
             title: 'Season Management',
             icon: 'el-icon-s-management',
             hidden: true
           },
           children: [
             {
               path: '/season-management',
               component: () => import('@/views/seasonManagement/index.vue'),
               meta: {
                 hidden: true,
                 dev: true
               }
             },
             {
               path: '/season-management/detail',
               component: () => import('@/views/seasonManagement/detail.vue'),
               meta: {
                 title: 'Detail',
                 inline: true,
                 hidden: true
               }
             }
           ]
         },
         {
           path: '/banner-management',
           name: 'Banner Weight Management',
           component: Layout,
           meta: {
             title: 'Banner Weight Management',
             icon: 'el-icon-bangzhu',
             hidden: true
           },
           children: [
             {
               path: '/banner-management',
               component: () => import('@/views/bannerManagement/index.vue'),
               meta: {
                 hidden: true,
                 dev: true
               }
             }
           ]
         },
         {
           path: '/setting',
           name: 'setting',
           redirect: '/setting/operation-records',
           component: Layout,
           meta: {
             title: 'Log Record',
             icon: 'el-icon-setting'
           },
           children: [
             {
               path: '/operation-records',
               component: () => import('@/views/config/operationRecords.vue'),
               meta: {
                 title: 'Data Operation'
               }
             }
           ]
         },
         {
           path: '/workload',
           name: 'workload',
           // redirect: '/setting/operation-records',
           component: Layout,
           meta: {
             title: 'Workload',
             icon: 'el-icon-notebook-1'
           },
           children: [
             {
               path: '/workload',
               component: () => import('@/views/workload/index.vue'),
               meta: {
                 hidden: true,
                 dev: true
               }
             }
           ]
         },
         {
           path: '/configuration',
           name: 'configuration',
           component: Layout,
           meta: {
             title: 'Configuration',
             icon: 'el-icon-setting'
           },
           children: [
             {
               path: '/configuration',
               component: () => import('@/views/configuration/index.vue'),
               meta: {
                 hidden: true
               }
             }
           ]
         }
         // {
         //   path: 'https://www.google.com',
         //   meta: {
         //     title: 'Google',
         //     icon: 'el-icon-search'
         //   },
         // }
         // {
         //   path: '/mapping-state',
         //   component: Layout,
         //   redirect: '/mapping-state/index',
         //   meta: {
         //     title: 'Mapping State',
         //     icon: 'el-icon-document'
         //   },
         //   children: [
         //     {
         //       path: '',
         //       component: () => import('@/views/mappingState/index.vue'),
         //       meta: {
         //         hidden: true
         //       }
         //     }
         //   ]
         // }
       ]

const router = new VueRouter({
  routes: [...defaultRoute, ...routes]
})

export default router
