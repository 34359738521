export const langData = [
  'ENG',
  'ZHO',
  'CMN',
  'JPN',
  'KOR',
  'SPA',
  'VIE',
  'THA',
  'MSA',
  'IND',
  'HIN',
  'SAU',
  'DEU',
  'FRA',
  'BRA',
  'RUS',
  'TR'
]

export const matchType = {
  1: 'Outrights',
  2: 'Matches',
  3: 'Esports',
  4: 'VR Sports'
}

export const mappingStatus = {
  0: 'Auto Mapping',
  1: 'Manual Mapping',
  2: 'Manual Unmapping'
}

export const marketStatusObj = {
         0: 'Active',
         1: 'Suspended',
         2: 'Deactivated',
         3: 'Settled',
         4: 'Cancelled',
         5: 'Settlement Rollback'
       }

export const statusOptions = [
  {
    label: 'All',
    value: ''
  },
  {
    label: 'Mapped',
    value: 1
  },
  {
    label: 'UnMapped',
    value: 0
  }
]
export const phaseOptions = [
  {
    label: 'Null',
    value: 0
  },
  {
    label: 'Playoff',
    value: 1
  },
  {
    label: 'Qualification',
    value: 2
  },
  {
    label: 'Promotion Playoffs',
    value: 3
  },
  {
    label: 'Relegation Playoffs',
    value: 4
  },
  {
    label: 'Preseason',
    value: 5
  },
  {
    label: 'Playoffs',
    value: 6
  },
  {
    label: 'Group Stage',
    value: 7
  },
  {
    label: 'Knockout Stage',
    value: 8
  },
  {
    label: '8th Finals',
    value: 9
  },
  {
    label: 'Quarter Finals',
    value: 10
  },
  {
    label: 'Semi Finals',
    value: 11
  },
  {
    label: '3rd Place Final',
    value: 12
  },
  {
    label: 'Final',
    value: 13
  },
  {
    label: 'Group A',
    value: 14
  },
  {
    label: 'Group B',
    value: 15
  },
  {
    label: 'Group C',
    value: 16
  },
  {
    label: 'Group D',
    value: 17
  },
  {
    label: 'Group E',
    value: 18
  },
  {
    label: 'Group F',
    value: 19
  },
  {
    label: 'Group G',
    value: 20
  },
  {
    label: 'Group H',
    value: 21
  },
  {
    label: 'Group I',
    value: 22
  },
  {
    label: 'Group J',
    value: 23
  },
  {
    label: 'Group K',
    value: 24
  },
  {
    label: 'Group L',
    value: 25
  },
  {
    label: 'Group M',
    value: 26
  },
  {
    label: 'Group N',
    value: 27
  },
  {
    label: 'Group O',
    value: 28
  },
  {
    label: 'Group P',
    value: 29
  },
  {
    label: '5th–8th Classification',
    value: 50
  },
  {
    label: '5th Place',
    value: 51
  },
  {
    label: '7th Place',
    value: 52
  },
  {
    label: 'Game 2',
    value: 53
  }
]
export const checkOptions = [
  {
    label: 'Check',
    value: 1
  },
  {
    label: 'Unconfirmd',
    value: 2
  },
  {
    label: 'Update',
    value: 3
  }
]

export const dataTypeOption = [
  {
    label: 'Standard Sport',
    value: 'Standard_Sport'
  },
  {
    label: 'Standard Region',
    value: 'Standard_Region'
  },
  {
    label: 'Standard Tournament',
    value: 'Standard_Tournament'
  },
  {
    label: 'Standard Team',
    value: 'Standard_Team'
  },
  {
    label: 'Standard Market',
    value: 'Standard_Market'
  },
  {
    label: 'Standard Event',
    value: 'Standard_Event'
  },
  {
    label: 'Standard Period',
    value: 'Standard_Period'
  },
  {
    label: 'Standard Option',
    value: 'Standard_Option'
  },
  {
    label: 'Standard Match Period',
    value: 'Standard_Match_Period'
  },
  {
    label: 'Standard Match',
    value: 'Standard_Match'
  },
  {
    label: 'Standard Match Status',
    value: 'Standard_Match_Status'
  },
  {
    label: 'Language',
    value: 'Language'
  },
  {
    label: 'Third Soprt',
    value: 'Third_Soprt'
  },
  {
    label: 'Third Region',
    value: 'Third_Region'
  },
  {
    label: 'Third Tournament',
    value: 'Third_Tournament'
  },
  {
    label: 'Third Team',
    value: 'Third_Team'
  },
  {
    label: 'Third Match',
    value: 'Third_Match'
  },
  {
    label: 'Third Market',
    value: 'Third_Market'
  },
  {
    label: 'Third Event',
    value: 'Third_Event'
  }
]

export const dataStatusOptions = [
  {
    label: 'Unconfirmed',
    value: -3
  },
  {
    label: 'No Source Data',
    value: -2
  },
  {
    label: 'Error/Disable',
    value: 0
  },
  {
    label: 'Normal',
    value: 1
  }
]

export const matchStatusMapping = {
  0: 'Ended',
  1: 'Postpone',
  2: 'Interrupted',
  3: 'Canceled',
  4: 'Upcoming',
  5: 'live'
}

export const dataStatusMapping = {
  '-3': 'Unconfirmed',
  '-2': 'No Source Data',
  '0': 'Error/Disable',
  '1': 'Normal'
}

export const operationOptions = [
  {
    label: 'Modify Mapping',
    value: 'Modify_Mapping'
  },
  {
    label: 'Add Standard Basic_Data',
    value: 'Add_Standard_Basic_Data'
  },
  {
    label: 'Again Mapping',
    value: 'Again_Mapping'
  },
  {
    label: 'Edit Standard Data',
    value: 'Edit_Standard_Data'
  },
  {
    label: 'Edit Third Data',
    value: 'Edit_Third_Data'
  },
  {
    label: 'Edit International',
    value: 'Edit International'
  }
]

export const originData = [
  {
    label: 'SR',
    value: 2
  },
  {
    label: 'TX',
    value: 1
  },
  {
    label: 'SR(T)',
    value: 3
  },
  // {
  //   label: 'BetConstruct',
  //   value: 4
  // },
  {
    label: 'LS',
    value: 5
  },
  {
    label: 'RB(T)',
    value: 6
  },
  // {
  //   label: 'YQ',
  //   value: 7
  // },
  {
    label: 'BG(T)',
    value: 8
  },
  {
    label: 'Kospt',
    value: 18
  },
  {
    label: 'IPTV',
    value: 9
  },
  {
    label: 'IMG',
    value: 23
  },
  {
    label: 'NaMi',
    value: 10
  }
]

export const originMapping = {
  1: 'TX',
  2: 'SR',
  3: 'SR(T)',
  // 4: 'BC',
  5: 'LS',
  6: 'RB(T)',
  7: 'YQ',
  8: 'BG(T)',
  18: 'Kospt',
  9: 'IPTV',
  23: 'IMG',
  10: 'NaMi'
}

// standard service api
export const SearchUrl = {
  STD_TEAM: 'teamManager/queryTeamName',
  STD_MATCH: 'matchManager/queryMatchName',
  STD_MARKET_TYPE: 'marketManager/findMarketType',
  STD_MARKET_LIST: '/marketManager/findMarketType',
  STD_REGION: 'regionManager/queryRegionName',
  STD_OUTRIGHT: 'outrightManager/findOutright',
  STD_SPORTS: 'sportTypeManager/querySportsName',
  STD_EVENT_TYPE: 'eventTypeManager/queryEventTypeName',
  STD_EVENT_NAME: 'eventCodeManager/queryEventName',
  STD_TOURNAMENT: 'tournamentManager/queryTournamentName'
}

// bookie constants state
export const BookieOptions = [
  { value: 1000, label: 'PinnacleSports' },
  { value: 1001, label: 'singbet' },
  { value: 1002, label: 'sbobet' },
  { value: 1003, label: '188bet' },
  { value: 1004, label: 'IBCbet' },
  { value: 2001, label: 'SportRadar' },
  { value: 2002, label: 'SportRadarOriginal' },
  { value: 4001, label: 'BetConstruct' },
  { value: 4002, label: 'BetConstructOriginal' }
]

export const MatchPeriodOptions = {
  1: [
    { value: 1001, label: 'Not started' },
    { value: 1002, label: 'First Half' },
    { value: 1003, label: 'Half Time' },
    { value: 1004, label: 'Second Half' },
    { value: 1005, label: 'Full Time' },
    { value: 1006, label: 'ET-First Half' },
    { value: 1007, label: 'ET-Half Time' },
    { value: 1008, label: 'ET-Second Half' },
    { value: 1009, label: 'ET-Finish' },
    { value: 1010, label: 'Penalty' },
    { value: 1011, label: 'Ended' }
  ],
  3: [
    { value: 3001, label: 'Not started' },
    { value: 3002, label: 'First Half' },
    { value: 3003, label: 'Half Time' },
    { value: 3004, label: 'Second Half' },
    { value: 3005, label: '1Q ' },
    { value: 3006, label: 'Quarter Break 1/2' },
    { value: 3007, label: '2Q' },
    { value: 3008, label: 'Quarter Break 2/3' },
    { value: 3009, label: '3Q' },
    { value: 3010, label: 'Quarter Break 3/4' },
    { value: 3011, label: '4Q' },
    { value: 3012, label: 'OverTime' },
    { value: 3013, label: 'Finish RT' },
    { value: 3014, label: 'Finish' }
  ],
  164: [
    { value: 164001, label: 'Not started' },
    { value: 164002, label: 'Game 1' },
    { value: 164003, label: 'Game Break 1/2' },
    { value: 164004, label: 'Game 2' },
    { value: 164005, label: 'Game Break 2/3' },
    { value: 164006, label: 'Game 3' },
    { value: 164007, label: 'Game Break 3/4' },
    { value: 164008, label: 'Game 4' },
    { value: 164009, label: 'Game Break 4/5	' },
    { value: 164010, label: 'Game 5' },
    { value: 164011, label: 'Finish' }
  ],
  165: [
    { value: 165001, label: 'Not started' },
    { value: 165002, label: 'Game 1' },
    { value: 165003, label: 'Game Break 1/2' },
    { value: 165004, label: 'Game 2' },
    { value: 165005, label: 'Game Break 2/3' },
    { value: 165006, label: 'Game 3' },
    { value: 165007, label: 'Game Break 3/4' },
    { value: 165008, label: 'Game 4' },
    { value: 165009, label: 'Game Break 4/5	' },
    { value: 165010, label: 'Game 5' },
    { value: 165011, label: 'Finish' }
  ],
  180: [
    { value: 180001, label: 'Not started' },
    { value: 180002, label: 'Game 1' },
    { value: 180003, label: 'Game Break 1/2' },
    { value: 180004, label: 'Game 2' },
    { value: 180005, label: 'Game Break 2/3' },
    { value: 180006, label: 'Game 3' },
    { value: 180007, label: 'Game Break 4/5' },
    { value: 180008, label: 'Game 4' },
    { value: 180009, label: 'Game Break 4/5	' },
    { value: 180010, label: 'Game 5' },
    { value: 180011, label: 'Finish' }
  ],
  5: [
    { value: 5001, label: 'Not started' },
    { value: 5002, label: 'Pause' },
    { value: 5003, label: 'Finish' },
    { value: 5005, label: '1th Set' },
    { value: 5006, label: 'Set Break 1/2' },
    { value: 5007, label: '2th Set' },
    { value: 5008, label: 'Set Break 2/3' },
    { value: 5009, label: '3th Set' },
    { value: 5010, label: 'Set Break 3/4' },
    { value: 5011, label: '4th Set' },
    { value: 5012, label: 'Set Break 4/5' },
    { value: 5013, label: '5th Set' }
  ]
}

// period constants state
export const PeriodOptions = [
  { value: 1001, label: 'SoccerFullTime' },
  { value: 1002, label: 'SoccerFirstHalf' },
  { value: 1003, label: 'SoccerSecondHalf' },
  { value: 1004, label: 'SoccerExtraFirstHalf' },
  { value: 1005, label: 'SoccerExtraSecondHalf' },
  { value: 1006, label: 'SoccerPenaltyKick' },
  { value: 1007, label: 'Soccer0-15Min' },
  { value: 1008, label: 'Soccer15-30Min' },
  { value: 1009, label: 'Soccer30_45Min' },
  { value: 1010, label: 'Soccer45-60Min' },
  { value: 1011, label: 'Soccer60-75Min' },
  { value: 1012, label: 'Soccer75-90Min' },
  { value: 1013, label: 'SoccerExtra' },
  { value: 3001, label: 'BasketballFullTime' },
  { value: 3002, label: 'BasketBallRegularTime' },
  { value: 3003, label: 'BasketballFirstHalf' },
  { value: 3004, label: 'BasketballSecondHalf' },
  { value: 3005, label: 'BasketballFirstQuarter' },
  { value: 3006, label: 'BasketballSecondQuarter' },
  { value: 3007, label: 'BasketballThirdQuarter' },
  { value: 3008, label: 'BasketballFourthQuarter' },
  { value: 3009, label: 'BasketballFirstOvertime' },
  { value: 3010, label: 'BasketballSecondOvertime' },
  { value: 3012, label: 'BasketballThirdOvertime' },
  { value: 3013, label: 'BasketballFourthOvertime' },
  { value: 3014, label: 'BasketballFifthOvertime' },
  { value: 3015, label: 'BasketballSixthOvertime' },
  { value: 3016, label: 'BasketballSeventhOvertime' },
  { value: 3017, label: 'BasketballEighthOvertime' },
  { value: 165001, label: 'LOLFullTime' },
  { value: 165002, label: 'LOLFirstMap' },
  { value: 165003, label: 'LOLSecondMap' },
  { value: 165004, label: 'LOLThirdMap' },
  { value: 165005, label: 'LOLFourthMap' },
  { value: 165006, label: 'LOLFifthMap' },

  { value: 164001, label: 'DATA2FullTime' },
  { value: 164002, label: 'DATA2FirstMap' },
  { value: 164003, label: 'DATA2SecondMap' },
  { value: 164004, label: 'DATA2ThirdMap' },
  { value: 164005, label: 'DATA2FourthMap' },
  { value: 164006, label: 'DATA2FifthMap' },

  { value: 179001, label: 'CS:GOFullTime' },
  { value: 179002, label: 'CS:GOFirstMap' },
  { value: 179003, label: 'CS:GOSecondMap' },
  { value: 179004, label: 'CS:GOThirdMap' },
  { value: 179005, label: 'CS:GOFourthMap' },
  { value: 179006, label: 'CS:GOFifthMap' },

  { value: 180001, label: 'GloryOfKingsFullTime' }
]

export const MarketStatusOptions = [
  { value: 0, label: 'Active' },
  { value: 1, label: 'Suspended' },
  { value: 2, label: 'Deactiveated' },
  { value: 3, label: 'Settled' },
  { value: 4, label: 'Cancelled' },
  { value: 5, label: 'HandedOver' }
]

// Price type constants state
export const PriceTypeOptions = [
  { value: 1, label: 'home' },
  { value: 2, label: 'away' },
  { value: 3, label: 'draw' },
  { value: 4, label: 'over' },
  { value: 5, label: 'under' },
  { value: 6, label: 'even' },
  { value: 7, label: 'odd' },
  { value: 8, label: 'yes' },
  { value: 9, label: 'no' },
  { value: 10, label: 'w1' },
  { value: 11, label: 'w2' }
]

// Result type constants state
export const ResultTypeOptions = [
  { value: 1001, label: 'SoccerHomeScore' },
  { value: 1002, label: 'SoccerAwayScore' },
  { value: 1003, label: 'SoccerHomeYellowCard' },
  { value: 1004, label: 'SoccerAwayYellowCard' },
  { value: 1005, label: 'SoccerHomeRedCard' },
  { value: 1006, label: 'SoccerAwayRedCard' },
  { value: 1007, label: 'SoccerHomeConner' },
  { value: 1008, label: 'SoccerAwayConner' },
  { value: 3001, label: 'BasketballHomeScore' },
  { value: 3002, label: 'BasketballAwayScore' },
  { value: 164001, label: 'Dota2HomeScore' },
  { value: 164002, label: 'Dota2AwayScore' },
  { value: 164003, label: 'Dota2HomeKill' },
  { value: 164004, label: 'Dota2AwayKill' },
  { value: 165001, label: 'LoLHomeScore' },
  { value: 165002, label: 'LoLAwayScore' },
  { value: 165003, label: 'LoLHomeKill' },
  { value: 165004, label: 'LoLAwayKill' },
  { value: 180001, label: 'KoGHomeScore' },
  { value: 180002, label: 'KoGAwayScore' },
  { value: 180003, label: 'KoGHomeKill' },
  { value: 180004, label: 'KoGAwayKill' },

  { value: 179001, label: 'CSHomeScore' },
  { value: 179002, label: 'CSAwayScore' },
  { value: 179003, label: 'CSHomeKill' },
  { value: 179004, label: 'CSAwayKill' },

  // { value: 15001, label: 'TableTennisHomeScore' },
  // { value: 15002, label: 'TableTennisAwayScore' },
  // { value: 15003, label: 'TableTennisHomeWinSet' },
  // { value: 15004, label: 'TableTenniAwayWinSet' },

  { value: 2001, label: 'IceHockeyHomeScore' },
  { value: 2002, label: 'IceHockeyAwayScore' },
  { value: 2003, label: 'IceHockeyHomeWinSet' },
  { value: 2004, label: 'IceHockeyAwayWinSet' },

  { value: 5001, label: 'TennisHomeSetScore' },
  { value: 5002, label: 'TennisHomeGameSetScore' },
  { value: 5003, label: 'TennisHomeScore' },
  { value: 5004, label: 'TennisHomeAceScore' },
  { value: 5005, label: 'TennisHomeDoubleFaultScore' },
  { value: 5006, label: 'TennisAwaySetScore' },
  { value: 5007, label: 'TennisAwayGameSetScore' },
  { value: 5008, label: 'TennisAwayScore' },
  { value: 5009, label: 'TennisAwayAceScore' },
  { value: 5010, label: 'TennisAwayDoubleFaultScore' }
]

// Result type kind constants state
export const ResultTypeKindOptions = [
  { value: 1, label: 'Start' },
  { value: 2, label: 'Game suspended' },
  { value: 3, label: 'end' },
  { value: 4, label: 'Game cancelled' },
  { value: 5, label: 'Score' },
  { value: 6, label: 'Conner' },
  { value: 7, label: 'Yellow Card' },
  { value: 8, label: 'Red Card' },
  { value: 9, label: 'Dangerous attack' },
  { value: 10, label: 'Free kick' },
  { value: 11, label: 'Substitution' },
  { value: 12, label: 'dangerous free kick' },
  { value: 63, label: '1st End' },
  { value: 64, label: '2nd Ended' }
]

// Event Name constants state
export const EventNameOptions = [
  { value: 1, label: 'Start' },
  { value: 2, label: 'Game suspended' },
  { value: 3, label: 'end' },
  { value: 4, label: 'Game cancelled' },
  { value: 5, label: 'Score' },
  { value: 6, label: 'Conner' },
  { value: 7, label: 'Yellow Card' },
  { value: 8, label: 'Red Card' },
  { value: 9, label: 'Dangerous attack' },
  { value: 10, label: 'Free kick' },
  { value: 11, label: 'Substitution' },
  { value: 12, label: 'dangerous free kick' },
  { value: 13, label: 'Extend the game global' },
  { value: 63, label: '1st End' },
  { value: 64, label: '2nd Ended' },
  { value: 65, label: 'Team1 1st Start' },
  { value: 66, label: 'Team2 1st Start' },
  { value: 67, label: 'Start OT1' },
  { value: 68, label: 'Start OT2' },
  { value: 69, label: 'End OT1' },
  { value: 70, label: 'End OT2' },
  { value: 71, label: 'Start OT1 Team1' },
  { value: 72, label: 'Start OT1 Team2' }
]

export const EventTypeNameOptions = [
  { value: 1, label: 'Start' },
  { value: 2, label: 'Game suspended' },
  { value: 3, label: 'end' },
  { value: 4, label: 'Game cancelled' },
  { value: 5, label: 'Score' },
  { value: 6, label: 'Corner' },
  { value: 7, label: 'Yellow Card' },
  { value: 8, label: 'Red Card' },
  { value: 9, label: 'Dangerous attack' },
  { value: 10, label: 'Free kick' },
  { value: 11, label: 'Substitution' },
  { value: 12, label: 'dangerous free kick' },
  { value: 63, label: '1st End' },
  { value: 64, label: '2nd Ended' },
  { value: 103, label: '1Points' },
  { value: 104, label: '2Points' },
  { value: 105, label: '3Points' },
  { value: 106, label: 'Free throw' },
  { value: 107, label: '1Q Start' },
  { value: 108, label: '2Q Start' },
  { value: 109, label: '3Q Start' },
  { value: 110, label: '4Q Start' },
  { value: 111, label: '1Q End' },
  { value: 112, label: '2Q End' },
  { value: 113, label: '3Q End' },
  { value: 114, label: '4Q End' }
]

// Match Status constants state
export const MatchStatusOptions = [
  { value: 0, label: 'Ended' },
  { value: 1, label: 'Postpone' },
  { value: 2, label: 'Interrupted' },
  { value: 3, label: 'Canceled' },
  { value: 4, label: 'Upcoming' },
  { value: 5, label: 'live' }
  // { value: 6, label: 'First Half' },
  // { value: 7, label: 'Half Time' },
  // { value: 8, label: 'Second Half' },
  // { value: 9, label: 'ET-First Half' },
  // { value: 10, label: 'ET-Half Time' },
  // { value: 11, label: 'ET-Second Half' },
  // { value: 13, label: 'Full Time' },
  // { value: 14, label: 'Penalty' },
  // { value: 15, label: 'Penalty' },
  // { value: 17, label: '1Q Start' },
  // { value: 18, label: '2Q Start' },
  // { value: 19, label: '3Q Start' },
  // { value: 20, label: '4Q Start' },
  // { value: 21, label: '1Q End' },
  // { value: 22, label: '2Q End' },
  // { value: 23, label: '3Q End' },
  // { value: 24, label: '4Q End' },
  // { value: 25, label: 'StartOverTime' },
  // { value: 26, label: 'EndOverTime' }
]

export const matchStatusList = [
  { value: 0, label: 'Ended' },
  { value: 1, label: 'Postponed' },
  { value: 2, label: 'Interrupted' },
  { value: 3, label: 'Cancelled' },
  { value: 4, label: 'Upcoming' },
  { value: 5, label: 'Live' },
  { value: 6, label: 'Delayed' },
  { value: 7, label: 'Abandoned' },
  { value: 8, label: 'Suspended' },
  { value: 9, label: 'Coverage Lost' }
]

// Clock Running constants state
export const ClockRunningOptions = [
  { value: true, label: 'True' },
  { value: false, label: 'False' }
]

// Remark constants state
export const RemarkOptions = [
  { value: 'Confirm', label: 'primary' },
  { value: 'Waiting', label: 'warning' },
  { value: 'Typed', label: 'danger' },
  { value: 'Correct', label: 'info' }
]

export const MatchMappingRemarkOptions = [
  { value: 'Confirm1', label: 'primary' },
  { value: 'Confirm2', label: 'success' },
  { value: 'Confirm3', label: 'warning' },
  { value: 'Waiting', label: 'danger' }
]

const callbackSoccer = item => {
  if (item.label.includes('Soccer')) {
    return (item['text'] = item.label.replace('Soccer', ''))
  }
}

const callbackBasketball = item => {
  if (item.label.includes('Basketball')) {
    return (item['text'] = item.label.replace('Basketball', ''))
  }
}

const callback = label => {
  return function(item) {
    if (item.label.includes(label)) {
      return (item['text'] = item.label.replace(label, ''))
    }
  }
}

export const SoccerRowArray = PeriodOptions.filter(callbackSoccer)

export const SoccerColArray = ResultTypeOptions.filter(callback('Soccer'))

export const BasketballRowArray = PeriodOptions.filter(callbackBasketball)

export const BasketballColArray = ResultTypeOptions.filter(
  callback('Basketball')
)

export const MatchResultTableData = {
  1: { cols: SoccerColArray },
  3: { cols: BasketballColArray },
  164: { cols: ResultTypeOptions.filter(callback('Dota2')) },
  165: { cols: ResultTypeOptions.filter(callback('LoL')) },
  180: { cols: ResultTypeOptions.filter(callback('KoG')) },
  179: { cols: ResultTypeOptions.filter(callback('CS')) },
  15: { cols: ResultTypeOptions.filter(callback('TableTenni')) },
  2: { cols: ResultTypeOptions.filter(callback('IceHockey')) },
  5: { cols: ResultTypeOptions.filter(callback('Tennis')) }
}
// export const MatchResultTableData = {
//   soccer: { rows: SoccerRowArray, cols: SoccerColArray },
//   basketball: { rows: BasketballRowArray, cols: BasketballColArray }
// }
