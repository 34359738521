/** axios封装
 * 请求拦截、相应拦截、错误统一处理
 */
import axios from 'axios'
import QS from 'qs'
import { Message } from 'element-ui'

import store from '../store/index'
import router from '@/router'
function Toast(str) {
  Message({
    type: 'error',
    message: str,
    customClass: 'zZindex'
  })
}

const baseUrl = process.env.VUE_APP_BASE_URL
axios.defaults.timeout = 10000

// 请求拦截器
axios.interceptors.request.use(
  (config) => {
    let url =
      config.url === '/login' || config.url === '/privilege/query/user/web/info'
        ? baseUrl
        : `${baseUrl}/data-support`
    config.baseURL = url

    // 每次发送请求之前判断是否存在token，如果存在，则统一在http请求的header都加上token，不用每次请求都手动添加了
    // 即使本地存在token，也有可能token是过期的，所以在响应拦截器中要对返回状态进行判断
    const token = store.getters.token
    token && (config.headers.token = token)

    return config
  },
  (error) => {
    return Promise.error(error)
  }
)

// 响应拦截器
axios.interceptors.response.use(
  (response) => {
      if (response.config.responseType === 'blob') {
        return Promise.resolve(response)
      }
      if (response.status === 200 && response.data.code === 0) {
        return Promise.resolve(response)
      } else if (response.data.code === 1010) { //  处理特殊错误状态码
        return Promise.resolve(response)
      } else {
        Toast(response.data.message)
        if (response.data.code === 8035) {
          // console.log('跳出登陆')
          router.replace({
            path: '/login',
            query: { redirect: router.currentRoute.fullPath }
          })
        }
        return Promise.reject(response)
      }
  },
  // 服务器状态码不是200的情况
  (error) => {
    console.log(error.response)
    if (error.response) {
      switch (error.response.status) {
        // 401: 未登录
        // 未登录则跳转登录页面，并携带当前页面的路径
        // 在登录成功后返回当前页面，这一步需要在登录页操作。
        case 401:
          // router.replace({
          //     path: '/login',
          //     query: { redirect: router.currentRoute.fullPath }
          // });
          break
        // 403 token过期
        // 登录过期对用户进行提示
        // 清除本地token和清空vuex中token对象
        // 跳转登录页面
        case 403:
          // 清除token
          // localStorage.removeItem('token');
          // store.commit('loginSuccess', null);     // 不太懂的话可不对状态码进行操作
          // // 跳转登录页面，并将要浏览的页面fullPath传过去，登录成功后跳转需要访问的页面
          // setTimeout(() => {
          //     router.replace({
          //         path: '/login',
          //         query: {
          //             redirect: router.currentRoute.fullPath
          //         }
          //     });
          // }, 1000);
          break
        // 404请求不存在
        case 404:
          Toast('网络请求不存在')
          break
        // 其他错误，直接抛出错误提示
        default:
          Toast(error.response.data.message)
      }
      return Promise.reject(error.response.data)
    } else {
      Toast(error)
    }
  }
)
/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
const downloadConf = {
  responseType: 'blob',
  timeout: 120000
}
export function get(url, params, type) {
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        params: params,
        ...(type === 'down' ? downloadConf : {})
      })
      .then(res => {
        if (type === 'down') {
          resolve(res)
        } else {
          resolve(res.data)
        }
      })
      .catch(err => {
        reject(err.data)
      })
  })
}
/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function post(url, params, type = 'json') {
  const arg = type === 'formData' ? QS.stringify(params) : params
  if (type === 'upload') {
    return `${baseUrl}/data-support/` + url
  }
    return new Promise((resolve, reject) => {
      axios
        .post(url, arg)
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err.data)
        })
    })
}

/**
 *  put方法，对应put请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function put(url, params, type = 'json') {
  const arg = type === 'formData' ? QS.stringify(params) : params

  return new Promise((resolve, reject) => {
    axios
      .post(url, arg)
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => {
        reject(err.data)
      })
  })
}

export function patch(url, params, type = 'json') {
  const arg = type === 'formData' ? QS.stringify(params) : params

  return new Promise((resolve, reject) => {
    axios
      .post(url, arg)
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => {
        reject(err.data)
      })
  })
}

export function del (url, params, type = 'json') {
  const arg = type === 'formData' ? QS.stringify(params) : params

  return new Promise((resolve, reject) => {
    axios
      .delete(url, arg)
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => {
        reject(err.data)
      })
  })
}

export default axios
