<template>
  <el-dropdown trigger="click" class="user-avatar" @command="handle">
    <div>
      <el-avatar size="small" icon="el-icon-user-solid"></el-avatar>
    </div>
    <el-dropdown-menu slot="dropdown" class="logout">
      <el-dropdown-item command="logout">
        logout
      </el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
export default {
  methods: {
    handle(type) {
      this.$store.dispatch('user/logout', type)
    }
  }
}
</script>
