import Cookies from 'js-cookie'

const state = {
  token: Cookies.get('token'),
  userInfo: JSON.parse(Cookies.get('userInfo') || '{}') || {},
  functionInfoList:
    JSON.parse(localStorage.getItem('functionInfoList') || '[]') || []
}

const actions = {
  login({ commit }, userInfo = {}) {
    commit('SET_TOKEN', userInfo)
  },
  logout({ commit }) {
    commit('SET_TOKEN', { token: '' })
  },
  infoList({ commit }, functionInfoList = []) {
    commit('SET_INFO', functionInfoList)
  }
}

const mutations = {
  SET_TOKEN: (state, userInfo) => {
    const { token } = userInfo
    state.token = token
    state.userInfo = userInfo
    Cookies.set('userInfo', userInfo)
    Cookies.set('token', token)
    if (!token) {
      location.reload()
    }
  },
  SET_INFO: (state, functionInfoList) => {
    state.functionInfoList = functionInfoList
    localStorage.setItem('functionInfoList', JSON.stringify(functionInfoList))
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
