import { post, get } from '@/utils/request'

export const savePriorityConfig = (data) => {
  return post('matchPriorityConfig/save', data, 'json')
}
export const getPriorityConfig = (data) => {
  return get('matchPriorityConfig/get', data, 'json')
}
export const statistics = (data) => {
  return post('opTask/statistics', data, 'json')
}
export const getConfiguration = data => {
  return get('/configuration/get', data, 'json')
}
export const saveConfiguration = data => {
  return post('configuration/save', data, 'json')
}
export const userList = (data) => {
  return post('opTask/userList', data, 'json')
}
export const toDoList = (data) => {
  return get('opTask/toDoList', data, 'json')
}
export const getNotification = (data) => {
  return post('opTask/getNotification', data, 'json')
}
export const countNotification = (data) => {
  return post('opTask/countNotification', data, 'json')
}
export const readNotification = (data) => {
  return post(
    'opTask/readNotification?ids=' +
      data.ids +
      `&userId=${data.userId}&userName=${data.userName}`,
    data,
    'json'
  )
}
export const readAllNotification = (data) => {
  return post('opTask/readAllNotification' +`?userId=${data.userId}&userName=${data.userName}`, data, 'json')
}