import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Element from 'element-ui'
import Cookies from 'js-cookie'
import promise from 'es6-promise'
// import 'element-ui/lib/theme-chalk/index.css'
import '@/assets/scss/element-variables.scss'
import '@/assets/iconfont/iconfont.css'
import {parseTime} from '@/utils'
// 国际化
import i18n from './assets/lang'
// import '@/mock'

import '@/router/permission'
promise.polyfill()

Vue.directive('Int', {
  bind: function(el) {
    const input = el.getElementsByTagName('input')[0]
    input.onkeyup = function(e) {
      if (input.value.length === 1) {
        input.value = input.value.replace(/[^0-9]/g, '')
      } else {
        input.value = input.value.replace(/[^\d]/g, '')
      }
      trigger(input, 'input')
    }
    input.onblur = function(e) {
      if (input.value.length === 1) {
        input.value = input.value.replace(/[^0-9]/g, '')
      } else {
        input.value = input.value.replace(/[^\d]/g, '')
      }
      trigger(input, 'input')
    }
  }
})

const trigger = (el, type) => {
  const e = document.createEvent('HTMLEvents')
  e.initEvent(type, true, true)
  el.dispatchEvent(e)
}

Vue.directive('enterNumber', {
  inserted: function(el) {
    el.addEventListener('keypress', function(e) {
      e = e || window.event
      let charcode = typeof e.charCode === 'number' ? e.charCode : e.keyCode
      let re = /\d/
      if (
        !re.test(String.fromCharCode(charcode)) &&
        charcode > 9 &&
        !e.ctrlKey
      ) {
        if (e.preventDefault) {
          e.preventDefault()
        } else {
          e.returnValue = false
        }
      }
    })
  }
})

Vue.directive('loadmore', {
  bind(el, binding) {
    const SELECTWRAP_DOM = el.querySelector(
      '.el-select-dropdown .el-select-dropdown__wrap'
    )
    SELECTWRAP_DOM.addEventListener('scroll', function() {
      const CONDITION = this.scrollHeight - this.scrollTop <= this.clientHeight
      if (CONDITION) {
        binding.value()
      }
    })
  }
})

 Vue.filter('formatTime', (val, formatTxt = '') => {
  if (!val) {
    return ''
  }
  return parseTime(val, formatTxt)
})
 Vue.filter('formatNum', (n, isNopoint = 1) => {
  if(!n)  {
    return n
  }
  //  let num = parseFloat(Number(val).toFixed(wei))
  //   let [integer, decimal] = String.prototype.split.call(num, '.')
  //   integer = integer.replace(/\d(?=(\d{3})+$)/g, '$&,') // 正则先行断言
  //   return `${integer}${decimal ? '.' + decimal : ''}`
  const regex = /\d{1,3}(?=(\d{3})+(\.|$))/g // 替换规则
  n = String(Math.round(n * Math.pow(10, 2))) // 乘100 四舍五入
  let integer = n.substr(0, n.length - 2).replace(regex, '$&,') // 最后两位前的为整数
  let decimal = n.substr(n.length - 2) // 最后两位为小数
  let result = ''
  if (isNopoint) {//不要小数
    result = `${integer || 0}`
  } else {
    result = `${integer || 0}.${decimal}`
  }
  return result
}
)

Vue.config.productionTip = false

Vue.use(Element, {
  size: Cookies.get('size') || 'medium', // set element-ui default size
  i18n: (key, value) => i18n.t(key, value)
})

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
