/**
 * The file enables `@/store/index.js` to import all vuex modules
 * in a one-shot manner. There should not be any reason to edit this file.
 */
import requireContext from 'require-context.macro'
let files
if (process.env.NODE_ENV === 'test') {
  files = requireContext('.', false, /\.js$/)
} else {
  files = require.context('.', false, /\.js$/)
}

const modules = {}

files.keys().forEach(key => {
  if (key === './index.js') return
  modules[key.replace(/(\.\/|\.js)/g, '')] = files(key).default
})

export default modules
